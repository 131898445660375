var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RoleMenu"},[_c('el-card',{staticStyle:{"margin-top":"8px"},attrs:{"shadow":"never"}},[_c('el-page-header',{on:{"back":_vm.goBack}})],1),_c('el-card',{staticStyle:{"margin-top":"8px"},attrs:{"shadow":"never"}},[_c('el-button',{attrs:{"size":"mini","disabled":_vm.editing || !_vm.hasPrivilege('biz.roleMenu.edit')},on:{"click":function($event){_vm.editing = true}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"primary","size":"mini","disabled":!_vm.editing},on:{"click":_vm.handleSave}},[_vm._v("保存")]),_c('el-button',{attrs:{"size":"mini","disabled":!_vm.editing},on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1),_c('el-card',{staticStyle:{"margin-top":"8px"},attrs:{"shadow":"never"}},[_c('el-checkbox-group',{model:{value:(_vm.bindCodes),callback:function ($$v) {_vm.bindCodes=$$v},expression:"bindCodes"}},[_c('el-table',{attrs:{"id":"menu","border":"","stripe":"","data":_vm.tableData,"size":"medium","highlight-current-row":true,"max-height":"560","row-key":"code","default-expand-all":"","tree-props":{ children: 'list' }}},[_vm._v(" > "),_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"80","align":"center","fixed":"left"}}),_c('el-table-column',{attrs:{"width":"200","label":"菜单名称","prop":"name"}}),_c('el-table-column',{attrs:{"width":"80","label":"显示","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['show'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['show']},on:{"change":function (checked) {
                                    _vm.checkboxChange(checked, scope.row.privs['show'], scope.row);
                                }}},[_vm._v("显示 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['show']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"80","label":"查询"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['open'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['open']},on:{"change":function (checked) {
                                    _vm.checkboxChange(checked, scope.row.privs['open'], scope.row);
                                }}},[_vm._v("查询 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['open']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"80","label":"新建"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['create'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['create']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['create'], scope.row);
                                }}},[_vm._v(" 新建 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['create']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"80","label":"编辑"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['edit'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['edit']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['edit'], scope.row);
                                }}},[_vm._v("编辑 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['edit']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"80","label":"审核"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['review'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['review']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['review'], scope.row);
                                }}},[_vm._v(" 审核 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['review']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"80","label":"删除"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['delete'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['delete']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['delete'], scope.row);
                                }}},[_vm._v(" 删除 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['delete']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"80","label":"导出"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['export'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['export']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['export'], scope.row);
                                }}},[_vm._v(" 导出 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['export']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"100","label":"出/入库"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['stock'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['stock']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['stock'], scope.row);
                                }}},[_vm._v(" 出入库 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['stock']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"110","label":"充值/退款"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['rechargeRefund'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['rechargeRefund']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['rechargeRefund'], scope.row);
                                }}},[_vm._v(" 充值/退款 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['rechargeRefund']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"100","label":"领/退"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editing && scope.row.privs['reserveRefund'])?_c('el-checkbox',{attrs:{"label":scope.row.privs['reserveRefund']},on:{"change":function (e) {
                                    _vm.checkboxChange(e, scope.row.privs['reserveRefund'], scope.row);
                                }}},[_vm._v(" 领/退 ")]):_vm._e(),(_vm.showCheck(scope.row.privs['reserveRefund']))?_c('div',[_c('span',{staticClass:"check"},[_c('i',{staticClass:"el-icon-check"})])]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }